import { Box, Button, Link, Typography } from "@mui/material";

function App() {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      textAlign="center"
      padding={2}
    >
      <Box marginBottom={4}>
        <Typography variant="h2" gutterBottom>
          Coming Soon
        </Typography>
        <Typography variant="h5" gutterBottom>
          Our website is under construction. We'll be here soon with our new
          awesome site.
        </Typography>
        <Link href="mailto:sales@pluhsoft.com" underline="none">
          <Button variant="contained" color="primary" size="large">
            Contact Us
          </Button>
        </Link>
      </Box>
      <Box>
        <Typography variant="h6" gutterBottom>
          If you want to intern with us, write to us at{" "}
          <Link href="mailto:hr@pluhsoft.com" underline="none">
            hr@pluhsoft.com
          </Link>
        </Typography>
      </Box>
    </Box>
  );
}

export default App;
